import React from "react";

const Portfolio = () => {
    return (
        <div>

            <div class="portfolioWrapper container-fluid">
                <div>
                    <h1 class="sectionHeader headingTextDecor fontColorGreen">Portfolio</h1>
                </div>
                <div class="porfolioFeaturedSites">
                    <a href="https://thehealingbrand.coggollie.org/" target="_blank" rel="noopener noreferrer">
                        <img class="portfolioImgSize" src="/images/TheHealingBrand_bubble.png" alt="CoggOllie - Merch Website"></img>
                    </a> 
                    <a href="https://birthdayeveryday365.com/" target="_blank" rel="noopener noreferrer">
                        <img class="portfolioImgSize" src="/images/BE365_bubble.png" alt="Birthday Everyday 365 Website"></img>
                    </a> 
                    <a href="https://soulercise.coggollie.org/" target="_blank" rel="noopener noreferrer">
                        <img class="portfolioImgSize" src="/images/Soulercise_bubble.png" alt="CoggOllie Soulercisse Website"></img>
                    </a>
                </div>
                <div class="portfolioToggleBtn">
                    <p class="d-inline-flex gap-1">
                        <button class="seeMoreBtn" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-label="See more">See More</button>
                    </p>
                </div>
                <div class="portfolioSeeMoreSection">
                    <div class="collapse multi-collapse">
                        <div class="seeMoreContent">
                            <div class="portfoliorSeeMoreSites">
                                <a href="https://management.coggollie.org/" target="_blank" rel="noopener noreferrer">
                                    <img class="portfolioImgSize" src="/images/CoggOllieManagement_bubble.png" alt="CoggOllie Brand Site"></img>
                                </a>
                                <a href="https://coggollie.org/" target="_blank" rel="noopener noreferrer">
                                    <img class="portfolioImgSize" src="/images/CoggOllie_bubble.png" alt="CoggOllie Brand Website"></img>
                                </a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
export default Portfolio;