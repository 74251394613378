import React from "react";
import Navbar from "../Components/Navbar";
import About from "./About";
import Portfolio from "./Portfolio";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "../Components/Footer";

const Home = () => {
    return (
        <div>
            <Navbar/>
            <section>
                <div class="landingContent">
                    <div>
                        <h1 class="landingHeader fontColorGreen">CodeNameKD &#123; 
                            <p class="headingQuote">skill-set: full-stack, front-end;<br></br>
                            status: open-to-opportunity;<br></br>
                            location: hybrid, washington-dc;</p>&#125;</h1>
                    </div>
                    <div class="profilePicture">
                        <img class="profileImg" src="/images/KD_profile_bubble4.png" alt="Kayla Durham; CodeNameKD"></img>
                    </div>
                </div>
            </section>
            
            <section class="section" id='about'>
                <div>
                    <About/>
                </div>
            </section>

            <section class="section" id='portfolio'>
                <div>
                    <Portfolio/>
                </div>
            </section>
            
            <section class="section bgColorTan" id='services'>
                <div>
                    <Services/>
                </div>
            </section>

            <section class="section bgColorGreen" id='contact'>
                <div>
                    <Contact/>
                </div>
            </section>
            <section>
                <div>
                    <Footer/>
                </div>
            </section>
    
        </div>
    );
}

export default Home;