import React from "react";

const Services = () => {
    return (
        <div>
            <div class="servicesWrapper container-fluid bgColorTan">
                <div>
                    <h1 class="sectionHeader headingTextDecor fontColorGreen">Services</h1>
                </div>

                <div class="servicesAccordion">
                    <div class="accordion accordion-flush" id="accordionFlush">
                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse1" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse1"
                                aria-label="Free Consultation">
                                    Free Consultation
                                </button>
                            </h4>
                            <div id="flush-collapse1" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body">Free virtual call to discuss your needs and what you are looking to achieve with your website.</div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse2" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse2"
                                aria-label="Brand Identity">
                                    Brand Identity
                                </button>
                            </h4>
                            <div id="flush-collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body">If you don't already have a brand I can help you define it. We will:
                                <br></br>&#8226;Make your business outline
                                <br></br>&#8226;Define who or what your business is
                                <br></br>&#8226;Identify you brand values
                                <br></br>&#8226;Create your logo 
                                <br></br>&#8226;Choose your business colors
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse3" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse3"
                                aria-label="Build Website">
                                    Build Functional & Responsive Website
                                </button>
                            </h4>
                            <div id="flush-collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body">Wether you already have a site or need one built, I help design and develop your site with up to date best practices to insure the best user experience.</div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse4" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse4"
                                aria-label="Site Hosting and Maintenance">
                                    Hosting & Monthly Site Maintenance
                                </button>
                            </h4>
                            <div id="flush-collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body"> I handle and provide all website hosting and maintenance to insure that your site is running at optimum performance so you don't have to!</div>
                            </div>
                        </div>
                        
                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse5" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse5"
                                aria-label="SEO">
                                    SEO Optimization
                                </button>
                            </h4>
                            <div id="flush-collapse5" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body">I work to help grow your site online presence to make sure you are easily searchable for site visitors. </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse6" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse6"
                                aria-label="Monthly updates">
                                    Monthly Site Updates
                                </button>
                            </h4>
                            <div id="flush-collapse6" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body">I handle basic website updates so you don't have to.
                                    <br></br>&#8226;Adding or updating images
                                    <br></br>&#8226;Adding or updating site information 
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header">
                                <button 
                                class="accordion-button collapsed d-block text-center" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#flush-collapse7" 
                                aria-expanded="false" 
                                aria-controls="flush-collapse7"
                                aria-label="Business admin">
                                    Business Admin
                                </button>
                            </h4>
                            <div id="flush-collapse7" class="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                                <div class="accordion-body">Need help with some of your business duties? I help with:
                                <br></br>&#8226;Organize scheduling
                                <br></br>&#8226;Calender management
                                <br></br>&#8226;Make Word, PowerPoint, & Excel templates 
                                <br></br>&#8226;Design flyers & pamphlets 
                                <br></br>&#8226;Bookkeeping 
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    );
}
export default Services;