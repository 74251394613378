import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = () => {
    return (
        <div>

            <div class="desktopNavbar">
                <div class="desktopNavWrapper container-fluid">
                    <div>
                        <ul>
                            <li>
                                <a class="desktopLogo fontColorGreen" href="/"> &#123; KD &#125;</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul class="desktopNavLinks">
                            <li class="desktopNavLink"><a class="navLink fontColorGreen" href='/'>Home</a></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink fontColorGreen" href='#about'>About</AnchorLink></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink fontColorGreen" href='#portfolio'>Portfolio</AnchorLink></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink fontColorGreen" href='#services'>Services</AnchorLink></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink fontColorGreen" href='#contact'>Contact</AnchorLink></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li><a class="navLink fontColorGreen" href="https://billing.stripe.com/p/login/fZeeXf2xsa1Q3Di8ww" target="_blank" rel="noopener noreferrer nofollow">Login</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            
            <div class="mobileNavbar">
                <nav class="navbar fixed-top bgColorTan">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="/"> <span class="navbar-brand-brackets fontColorGreen navbar-brand-size">&#123; KD &#125;</span></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" data-bs-dismiss="offcanvas">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">&#123; KD &#125;</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li class="nav-item">
                                    <a class="nav-link" href="/">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#about">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#services">Services</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#portfolio">Portfolio</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#contact">Contact</a>
                                </li>
                                <br></br>
                                <li class="nav-item">
                                    <a class="nav-link" href="https://billing.stripe.com/p/login/fZeeXf2xsa1Q3Di8ww" target="_blank" rel="noopener noreferrer">Login</a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>   
            </div>
           
            

        </div>
    );
}

export default Navbar;